import { DialogController, DialogService } from 'aurelia-dialog';
import { autoinject } from 'aurelia-framework';
import { UserSelectModel } from 'user-model';

export class AssignDialogModel {
    message: string;
    title: string;

    detail: string;
    users: UserSelectModel[];

    constructor(title: string, message: string, users: UserSelectModel[]) {
        this.message = message;
        this.title = title;

        this.users = users;
    }
}

export type AssignUsersDialogOkResult = {
    isAssignedToMe: boolean;
    selectedUsers: UserSelectModel[];
};

@autoinject
export class AssignDialog {
    controller: DialogController;
    model: AssignDialogModel;
    assignToMe: boolean = true;

    constructor(controller: DialogController) {
        this.controller = controller;
    }

    activate(model: AssignDialogModel) {
        this.model = model;
    }

    async ok() {
        const result: AssignUsersDialogOkResult = {
            isAssignedToMe: this.assignToMe,
            selectedUsers: this.model.users.filter((u) => u.selected),
        };
        this.controller.ok(result);
    }

    select(user: UserSelectModel): boolean {
        user.selected = !user.selected;

        return true; // Needed for keyboard navigation
    }
}
