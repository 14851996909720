import { createPaginatedResponseSchema } from 'context/api/utils/paginated-response';
import { z } from 'zod';

const AVAILABLE_PERIODS = ['Today', 'OneWeek', 'OneMonth', 'ThreeMonths', 'OneYear', 'ThreeYears', 'Infinity'] as const;
export const periods = z.enum(AVAILABLE_PERIODS);

const transactionHistoryPeriodSchema = z.object({
    timestamp: z.string(),
    inbound: z.number().nullable(),
    outbound: z.number().nullable(),
});
const transactionHistoryResponse = z.object({
    items: transactionHistoryPeriodSchema.array(),
});

const propertiesSchema = z.array(
    z.object({
        name: z.enum(['tx_field', 'tx_party_field']),
    }),
);

const transactionListParamsSchema = z.object({
    from: z.date().nullable(),
    to: z.date().nullable(),
    skip: z.number().nullable().optional(),
});

const transactionSchema = z.object({
    amount: z.object({
        currency: z.string(),
        value: z.number(),
    }),
    booked: z.string(),
    customerId: z.string(),
    id: z.string(),
    properties: propertiesSchema,
    transactionParty: z.object({
        customerId: z.string().nullable(),
        properties: propertiesSchema,
    }),
});

const PaginatedResponse = createPaginatedResponseSchema(transactionSchema);

export type TransactionHistoryResponse = z.infer<typeof transactionHistoryResponse>;
export type TransactionHistoryPeriod = z.infer<typeof periods>;
export type TransactionListReponse = z.infer<typeof PaginatedResponse>;
export type TransactionListParams = z.infer<typeof transactionListParamsSchema>;
export type Transaction = z.infer<typeof transactionSchema>;
