import { z } from 'zod';

const AVAILABLE_SORTABLE_ORDERS = ['asc', 'desc'] as const;
const orderEnum = z.enum(AVAILABLE_SORTABLE_ORDERS);

export const orderSchema = z.object({
    order: orderEnum.nullable(),
});
export const pageSchema = z.object({
    no: z.number(),
});

export type SortableOrder = z.infer<typeof orderEnum>;
