export class StorageHandler {
    static saveToStorage(key: string, item: StorageItem): void {
        try {
            localStorage.setItem(key, JSON.stringify(item));
        } catch (e) {
            console.error('Error saving to localStorage', e);
        }
    }

    static getFromStorage(key: string): StorageItem | null {
        try {
            const value = localStorage.getItem(key);
            if (value === null) return null;
            const parsedPerson: StorageItem = JSON.parse(value);
            return parsedPerson;
        } catch (e) {
            console.error('Error retrieving from localStorage', e);
            return null;
        }
    }
}

interface StorageItem {
    field: string;
    sortOrder: string;
}
