import { LitElement, css, html } from 'lit';
import { customElement, state, property } from 'lit/decorators.js';
import { when } from 'lit/directives/when.js';
import { Ref, createRef, ref } from 'lit/directives/ref.js';

import 'pli/pli-card';
import 'pli/pli-text';
import 'pli/pli-user-bubble';
import 'pli/pli-textarea';
import 'pli/pli-button';
import 'pli/pli-icon';

import { consume } from '@lit/context';
import { CurrentUser, currentUserContext } from 'context/current-user-context';
import { UserModel } from 'user-model';
import { styles } from 'pli/styles';
import { CommentModel } from 'schema/comment/comment-schema';
import { CaseApi, caseApiContext } from 'context/api/case/case-api-context';
import { CustomerApi, customerApiContext } from 'context/api/customer/customer-api-context';
import { getRelativeDiff } from 'utils/datetime-formatter';
import { confirmServiceContext } from 'context/confirm-service-context';
import { ConfirmService } from 'components/confirmation/confirm-service';
import '../comments/comments-form';
import { PliInputChangeEvent } from 'pli/controllers/input-controller';
import { repeat } from 'lit/directives/repeat.js';

@customElement('comments-area')
class CommentsArea extends LitElement {
    static styles = [styles.grid, styles.flex, styles.padding, styles.form, css``];

    @property({ type: Array })
    comments: CommentModel[];

    @property({ type: Boolean, reflect: true })
    disabled?: boolean;

    @consume({ context: caseApiContext })
    caseApi?: CaseApi;

    @consume({ context: customerApiContext })
    customerApi?: CustomerApi;

    @consume({ context: currentUserContext })
    currentUser?: CurrentUser;

    @consume({ context: confirmServiceContext })
    confirmService?: ConfirmService;

    @state()
    _value: string | null = null;

    @state()
    _profile: UserModel = new UserModel();

    @state()
    _editingCommentId: CommentModel['commentId'] | null = null;

    @state()
    _replyingComment: CommentModel | null = null;

    async connectedCallback() {
        super.connectedCallback();

        if (!this.currentUser) {
            return;
        }

        this._profile = await this.currentUser.get();
    }

    onChange = (event: PliInputChangeEvent) => {
        this._value = event.detail.value;
    };

    resetState = () => {
        this._value = null;
        this.setEditModeForComment(null);
        this.setReplyModeForComment(null);
    };

    add = async () => {
        const _event = new CustomEvent('add', {
            composed: true,
            detail: {
                value: this._value,
            },
        });
        this.dispatchEvent(_event);
        this.resetState();
    };

    edit = async (commentId: CommentModel['commentId']) => {
        const _event = new CustomEvent('edit', {
            composed: true,
            detail: {
                value: this._value,
                commentId: commentId,
            },
        });
        this.dispatchEvent(_event);
        this.resetState();
    };

    reply = async (parentId: number) => {
        const _event = new CustomEvent('reply', {
            composed: true,
            detail: {
                value: this._value,
                parentId: parentId,
            },
        });
        this.dispatchEvent(_event);
        this.resetState();
    };

    delete = async (commentId: CommentModel['commentId']) => {
        this.confirmService?.confirm(
            'Delete comment?',
            'Are you sure you want to delete the comment. This action cannot be undone.',
            async () => {
                const _event = new CustomEvent('delete', {
                    composed: true,
                    detail: {
                        value: commentId,
                    },
                });
                this.dispatchEvent(_event);
                this.resetState();
            },
        );
    };

    get hasValue(): boolean {
        return this._value !== null && this._value.length > 0;
    }

    setEditModeForComment = (commentId: CommentModel['commentId'] | null) => {
        this._editingCommentId = commentId;
    };

    setReplyModeForComment = async (payload: CommentModel | null) => {
        const hasParent = Boolean(payload && payload.parentId);
        const parentComment = this.comments.find((item) => item.commentId === payload?.parentId);

        if (hasParent && parentComment) {
            this._replyingComment = parentComment;
        } else {
            this._replyingComment = payload;
        }

        if (!Boolean(this._replyingComment)) {
            return;
        }
        const commentHeightOffset = 130;
        window.scrollBy(0, commentHeightOffset);     
    };

    renderComment = (comment: CommentModel) => {
        const {
            delete: deleteComment,
            edit: saveComment,
            reply: replyToComment,
            setEditModeForComment,
            onChange,
            setReplyModeForComment,
            _editingCommentId,
            _replyingComment,
        } = this;

        const hasReplies = Boolean(comment.children.length);
        const isInitialMode =
            comment.commentId !== _editingCommentId && comment.commentId !== _replyingComment?.commentId;

        const isEditingComment = (id: CommentModel['commentId']) => id === _editingCommentId;
        const isReplyingToComment = (id: CommentModel['commentId']) => id === _replyingComment?.commentId;

        const _renderInitialButtonGroup = (item: CommentModel) => html`
            ${when(
                !this.disabled,
                () =>
                    html`<div class="flex items-center gap-1">
                        <pli-button variant="text" .onClick="${() => setReplyModeForComment(item)}">
                            <pli-icon slot="icon-left" name="reply"></pli-icon>
                            Reply
                        </pli-button>
                        <pli-button variant="text" .onClick="${() => setEditModeForComment(item.commentId)}">
                            <pli-icon slot="icon-left" name="pencil"></pli-icon>
                            Edit
                        </pli-button>
                        ${when(
                            !item.children?.length,
                            () => html`
                                <pli-button .onClick="${() => deleteComment(item.commentId)}" variant="text">
                                    <div slot="icon-left" style="color: var(--button-background-destructive)">
                                        <pli-icon name="trash"></pli-icon>
                                    </div>
                                    <span style="color: var(--button-background-destructive)">Delete</span>
                                </pli-button>
                            `,
                        )}
                    </div>`,
            )}
        `;

        const _renderEditingButtonGroup = (item: CommentModel) => html`
            <div class="flex items-center gap-1">
                <pli-button variant="text" .onClick="${() => setEditModeForComment(null)}">Cancel</pli-button>
                <pli-button variant="text" .onClick="${() => saveComment(item.commentId)}" .disabled="${!this.hasValue}">Save</pli-button>
            </div>
        `;

        const _renderReplyForm = (item: CommentModel | null) => {
            if (!item) {
                return null;
            }
            
            return html`
                <div class="grid-vertical gap-1">
                    <comments-form @change="${onChange}" .disabled="${this.disabled}" .isEditing="${true}"></comments-form>
                    <div class="flex items-center gap-1 pl-3-5">
                        <pli-button variant="text" .onClick="${() => setReplyModeForComment(null)}">Cancel</pli-button>
                        <pli-button variant="text" .onClick="${() => replyToComment(item.parentId ?? item.commentId)}" .disabled="${!this.hasValue}">
                            Comment
                        </pli-button>
                    </div>
                </div>
            `;
        };

        const renderTemplate = (item?: CommentModel) => {
            if (!item) {
                return null;
            }
            return html`
                <div class="grid-vertical">
                    <comments-form                        
                        ?isEditing="${isEditingComment(item.commentId)}"
                        value="${item.text}"
                        @change="${onChange}"
                        .disabled="${this.disabled}"
                    >
                        <pli-text slot="timestamp">${getRelativeDiff(item.createdTime)}</pli-text>

                        ${when(
                            isEditingComment(item.commentId),
                            () => _renderEditingButtonGroup(item),
                            () => html`<div .inert="${!isInitialMode}">${_renderInitialButtonGroup(item)}</div>`,
                        )}
                    </comments-form>
                </div>
            `;
        };
        return html`
            <div class="grid-vertical gap-2">
                <!-- Main comment -->
                ${renderTemplate(comment)}
                <!-- Replies -->
                ${when(
                    hasReplies,
                    () => html`
                        <div class="pl-3-5 grid-vertical gap-1">
                            ${repeat(comment.children, (item) => renderTemplate({ ...item }))}
                        </div>
                    `,
                )}
                <!-- Reply to thread -->
                ${when(
                    isReplyingToComment(comment.commentId),
                    () => html`<div class="pl-3-5">${_renderReplyForm(_replyingComment)}</div>`,
                )}
            </div>
        `;
    };

    render() {
        const { comments, onChange, add: onSubmit, renderComment, _editingCommentId, _replyingComment } = this;
        const hasItems = Boolean(comments?.length);
        const isEditingOrReplying = _editingCommentId !== null || _replyingComment !== null;

        return html`
            <pli-card>
                <div class="grid-vertical gap-2">
                    <slot></slot>
                    ${when(
                        hasItems,
                        () => html`
                            <div class="pl-3-5 flex-1 grid-vertical gap-2">
                                ${repeat(
                                    comments,
                                    (comment) => html`
                                        <div class="grid-vertical gap-1">${renderComment(comment)}</div>
                                    `,
                                )}
                            </div>
                        `,
                    )}
                    ${when(
                        !this.disabled && !isEditingOrReplying,
                        () => html`
                            <comments-form
                                value="${this._value}"
                                @change="${onChange}"
                                .onSubmit="${onSubmit}"
                                .isEditing="${true}"
                                .disabled="${this.disabled}"
                            >
                            </comments-form>
                        `,
                    )}
                </div>
            </pli-card>
        `;
    }
}
