import { createPaginatedResponseSchema } from 'context/api/utils/paginated-response';
import { userSchema } from '../user/user-schema';
import { z } from 'zod';

export const documentSchema = z.object({
    documentId: z.number(),
    customerId: z.string(),
    uploadedTime: z.date(),
    uploadedBy: userSchema,
    name: z.string(),
    contentType: z.string(),
});

const documentCreatedSchema = documentSchema.pick({ documentId: true });

const PaginatedResponse = createPaginatedResponseSchema(documentSchema);

export type DocumentPaginatedResponse = z.infer<typeof PaginatedResponse>;
export type DocumentCreatedResponse = z.infer<typeof documentCreatedSchema>;
export type DocumentModel = z.infer<typeof documentSchema>;
