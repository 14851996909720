import { z } from 'zod';
import { createPaginatedResponseSchema } from '../../context/api/utils/paginated-response';

export const userSchema = z.object({
    userId: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    fullName: z.string(),
});
const UserPaginatedResponse = createPaginatedResponseSchema(userSchema);

export type User = z.infer<typeof userSchema>;
export type UserPaginatedResponse = z.infer<typeof UserPaginatedResponse>;
