import { autoinject } from 'aurelia-framework';
import { DialogService } from 'aurelia-dialog';
import { AssignDialog, AssignDialogModel, AssignUsersDialogOkResult } from './assign-dialog';
import { UserSelectModel } from 'user-model';

export type AssignUsersDialogResult = {
    ok: boolean;
    result: AssignUsersDialogOkResult;
};

@autoinject
export class AssignService {
    dialogService: DialogService;

    constructor(dialogService: DialogService) {
        this.dialogService = dialogService;
    }

    showAssignDialog(title: string, message: string, users: UserSelectModel[]): Promise<AssignUsersDialogResult> {
        const model: AssignDialogModel = new AssignDialogModel(title, message, users);

        return new Promise<AssignUsersDialogResult>((resolve) => {
            this.dialogService
                .open({ viewModel: AssignDialog, model: model, lock: false })
                .whenClosed(async (response) => {
                    if (!response.wasCancelled) {
                        const r: AssignUsersDialogResult = {
                            ok: true,
                            result: response.output,
                        };

                        resolve(r);
                    } else {
                        const r: AssignUsersDialogResult = {
                            ok: false,
                            result: null,
                        };

                        resolve(r);
                    }
                });
        });
    }
}
