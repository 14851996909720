import { consume } from '@lit/context';
import { currentUserContext, CurrentUser } from 'context/current-user-context';
import { LitElement, css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { when } from 'lit/directives/when.js';
import { PliInputChangeEvent } from 'pli/controllers/input-controller';
import { styles } from 'pli/styles';
import { UserModel } from 'user-model';
import 'pli/pli-user-bubble';
import 'pli/pli-textarea';
import 'pli/pli-button';

@customElement('comments-form')
class CommentsForm extends LitElement {
    static styles = [styles.grid, styles.flex, css``];

    @property()
    value?: string;

    @property({ type: Boolean })
    isEditing?: boolean;

    @property({ type: Boolean, reflect: true })
    disabled?: boolean;

    @property()
    onSubmit?: () => void;

    @consume({ context: currentUserContext })
    currentUser?: CurrentUser;

    @state()
    _profile: UserModel = new UserModel();

    async connectedCallback() {
        super.connectedCallback();
        if (!this.currentUser) {
            return;
        }
        this._profile = await this.currentUser.get();
    }

    _onChange = (event: PliInputChangeEvent) => {
        const _event: CommentFormUpdateEvent = new CustomEvent('change', {
            composed: true,
            detail: {
                value: event.detail.value,
            },
        });
        this.dispatchEvent(_event);
    };

    _onSubmit = () => {
        if (!this.onSubmit) {
            return;
        }

        this.onSubmit();
    };

    get hasCommentText(): boolean {
        return this.value !== undefined && this.value.length > 0;
    }

    render() {
        const { _profile, _onChange, onSubmit, _onSubmit, isEditing } = this;
        return html`
            <div class="flex gap-1">
                <pli-user-bubble
                    firstName="${_profile?.firstName}"
                    lastName="${_profile?.lastName}"
                    userId="${_profile?.userId}"
                ></pli-user-bubble>
                <div class="flex-1 grid-vertical gap-05">
                    <slot name="timestamp"></slot>
                    <pli-textarea
                        ?disabled="${this.disabled || !isEditing}"
                        value="${this.value ?? ''}"
                        placeholder="Your comment"
                        @change="${_onChange}"
                    ></pli-textarea>
                    <slot></slot>
                    ${when(onSubmit, () => html`<pli-button .onClick="${_onSubmit}" size="lg" .disabled="${!this.hasCommentText}">Comment</pli-button>`)}
                </div>
            </div>
        `;
    }
}

export type CommentFormUpdateEvent = CustomEvent<{ value: string }>;
