import { z } from 'zod';
import { userSchema } from './../user/user-schema';
import { documentSchema } from './../document/document-schema';
import { caseStatusKeys } from 'domain/status/case-status';

export const caseStatusTypes = z.enum(caseStatusKeys);

export const caseDetailsSchema = z.object({
    caseId: z.number(),
    customerId: z.string(),
    reportFilingId: z.number(),
    name: z.string(),
    description: z.string(),
    comment: z.string(),
    created: z.date(),
    assigned: userSchema.array(),
    status: caseStatusTypes,
    customer: z.object({
        firstName: z.string(),
        lastName: z.string(),
    }),
    closed: z.boolean(),
    reportFilingName: z.string(),
    documents: documentSchema.array(),
});

export type CaseDetails = z.infer<typeof caseDetailsSchema>;
